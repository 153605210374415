import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {IAlertTemplate} from './models/alert-template.model';
import {AddAlertTemplateCommand} from './models/add-alert-template.command';
import {BillType} from '../bills/enums/billType.enum';

@Injectable({
  providedIn: 'root'
})
export class AlertTemplatesService {

  constructor(private _http: HttpClient) {
  }

  /**
   * Get user info
   */
  public getAlertTemplates(): Observable<Array<IAlertTemplate>> {
    return this._http
      .get<Array<IAlertTemplate>>('/api/alert-templates');
  }

  /**
   * Get alert by id
   * @param id
   */
  public getAlertTemplate(id: string): Observable<IAlertTemplate> {
    return this._http
      .get<IAlertTemplate>(`/api/alert-templates/${id}`);
  }

  /**
   * Create new alert
   */
  public createAlertTemplate(command: AddAlertTemplateCommand): Observable<string> {
    return this._http
    .put<string>('/api/alert-templates', command);
  }

  /**
   * Update alert
   * @param id
   * @param command
   */
  public updateAlertTemplate(id: string, command: AddAlertTemplateCommand): Observable<string> {
    return this._http
    .post<string>(`/api/alert-templates/${id}`, command);
  }

  /**
   * Remove alert
   * @param id
   */
  public remove(id: string): Observable<void>{
    return this._http
      .delete<void>(`/api/alert-templates/${id}`,);
  }

  /**
   * Test alert
   * @param id
   * @param billType
   * @param minPrice
   */
  public test(id:string, billType: BillType, minPrice: number): Observable<void>{
    return this._http
      .post<void>(`/api/alert-templates/${id}/test/${billType}`, minPrice);
  }

  /**
   * Test alert
   * @param billType
   * @param minPrice
   */
  public testGeneric(billType: BillType, minPrice: number): Observable<void>{
    return this._http
      .post<void>(`/api/alert-templates/test-bill/${billType}`, minPrice);
  }


}
