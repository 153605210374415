import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload/interface';
import {BillTypeData} from '../../../services/bills/dataSources/bill-type-data';
import {BillType} from '../../../services/bills/enums/billType.enum';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertTemplatesService} from '../../../services/alert-templates/alert-templates.service';
import {finalize} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {AddAlertTemplateCommand} from '../../../services/alert-templates/models/add-alert-template.command';

@Component({
  selector: 'app-edit-alert',
  templateUrl: './edit-alert.component.html',
  styleUrls: ['./edit-alert.component.scss']
})
export class EditAlertComponent implements OnInit {
  @Input() public alertTemplateId?: string;
  @Output() public added = new EventEmitter();
  public BillTypes = Object.keys(BillTypeData).map(x => parseInt(x) as BillType);
  public BillTypeData = BillTypeData;
  public isLoaded = false;
  public isEdit = false;
  public animationFileList: NzUploadFile[] = [];
  public soundFileList: NzUploadFile[] = [];
  public form: FormGroup<{
    animationFileId: FormControl<string | null>,
    soundFileId: FormControl<string | null>,
    billType: FormControl<BillType | null>,
    volume: FormControl<number | null>,
    minPrice: FormControl<number | null>,
  }>;
  public isSaving = false;


  constructor(formBuilder: FormBuilder, private _alertTemplatesService: AlertTemplatesService, private _message: NzMessageService) {
    this.form = formBuilder.group({
      animationFileId: null as (string | null),
      soundFileId: null as (string | null),
      billType: [null as (BillType | null), [Validators.required]],
      volume: 1.0,
      minPrice: null as (number | null)
    });
  }

  handleAnimationFileChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.form.get('animationFileId')?.patchValue(info.file.response.id);
    } else if (info.file.status === 'removed') {
      this.form.get('animationFileId')?.patchValue(null);
    }
  }

  handleSoundFileChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.form.get('soundFileId')?.patchValue(info.file.response.id);
    } else if (info.file.status === 'removed') {
      this.form.get('soundFileId')?.patchValue(null);
    }
  }

  save() {
    const command: AddAlertTemplateCommand = {
      billType: this.form.value.billType!,
      volume: this.form.value.volume!,
      minPrice: this.form.value.minPrice,
      soundFileId: this.form.value.soundFileId,
      animationFileId: this.form.value.animationFileId
    };

    this.isSaving = true;
    (this.isEdit
        ? this._alertTemplatesService.updateAlertTemplate(this.alertTemplateId!, command)
        : this._alertTemplatesService.createAlertTemplate(command)
    ).pipe(
      finalize(() => this.isSaving = false)
    )
      .subscribe((x) => {
        if (!this.isEdit) {
          this.added.emit();
        }
        this.alertTemplateId = x;
        this.isEdit = true;
        this._message.success('Изменения сохранены');
      }, () => this._message.error('Опаньки.. Что-то пошло не так'));
  }

  ngOnInit(): void {
    this.isEdit = this.alertTemplateId !== undefined;
    if (this.isEdit) {
      this._alertTemplatesService.getAlertTemplate(this.alertTemplateId!)
        .subscribe((alertTemplate) => {

          this.form.patchValue({
            animationFileId: alertTemplate.animationFile?.id,
            soundFileId: alertTemplate.soundFile?.id,
            billType: alertTemplate.billType,
            volume: alertTemplate.volume,
            minPrice: alertTemplate.minPrice
          });

          if (alertTemplate.animationFile !== undefined) {
            this.animationFileList.push({
              uid: alertTemplate.animationFile!.id,
              name: alertTemplate.animationFile!.fileName,
              status: 'done',
              url: 'api/files/' + alertTemplate.animationFile!.id
            });
          }

          if (alertTemplate.soundFile !== undefined) {
            this.soundFileList.push({
              uid: alertTemplate.soundFile!.id,
              name: alertTemplate.soundFile!.fileName,
              status: 'done',
              url: 'api/files/' + alertTemplate.soundFile!.id
            });
          }

          this.isLoaded = true;
        }, () => {
          this._message.error('Опаньки.. Что-то пошло не так');
        });
    } else {
      this.isLoaded = true;
    }
  }

  test() {
    this._alertTemplatesService
      .test(this.alertTemplateId!, this.form.value.billType!, this.form.value.minPrice ?? 0)
      .subscribe(() => this._message.success('Тестовое сообщение создано'));
  }
}
