<ng-container *ngIf="isLoaded else skeleton">
  <div class="clearfix">
    <div nz-row>
      <form nz-form [formGroup]="form" (ngSubmit)="save()" nz-col [nzSm]="24" [nzMd]="12" [nzLg]="4"
            nzLayout="vertical">
        <!-- TITLE -->
        <nz-form-item>
          <nz-form-label nzRequired nzFor="billTypes">Название</nz-form-label>
          <nz-form-control nzErrorTip="Введите название">
            <input nz-input formControlName="title"/>
          </nz-form-control>
        </nz-form-item>
        <!-- TARGET AMOUNT -->
        <nz-form-item>
          <nz-form-label nzFor="animationFile">Цель (в рублях)</nz-form-label>
          <nz-form-control>
            <nz-input-number [nzMin]="0" formControlName="targetAmount"></nz-input-number>
          </nz-form-control>
        </nz-form-item>

        <!-- IS ACTIVE -->
        <nz-form-item>
          <label nz-checkbox formControlName="isActive">Активировать</label>
        </nz-form-item>

        <!-- SUBMIT BUTTON -->
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [nzLoading]="isSaving">Сохранить</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <nz-skeleton [nzActive]="true" [nzParagraph]="{rows: 6}"></nz-skeleton>
</ng-template>
