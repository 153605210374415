import {Component, OnInit} from '@angular/core';
import {BillType} from '../../services/bills/enums/billType.enum';
import {BillTypeData} from '../../services/bills/dataSources/bill-type-data';
import {AlertTemplatesService} from '../../services/alert-templates/alert-templates.service';
import {finalize} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {IAlertTemplate} from '../../services/alert-templates/models/alert-template.model';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  public alertTemplateId?: string;
  public BillType = BillType;
  public BillTypeData = BillTypeData;

  public editShown = false;
  public isLoading = false;
  public alertTemplates: Array<IAlertTemplate> = [];
  public testPrice: number = 0;
  public testBillType?: BillType;
  public BillTypes = Object.keys(BillTypeData).map(x => parseInt(x) as BillType);



  constructor(private _alertTemplatesService: AlertTemplatesService, private _message: NzMessageService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  edit(alertTemplateId: string) {
    this.alertTemplateId = alertTemplateId;
    this.editShown = true;
  }

  add() {
    this.alertTemplateId = undefined;
    this.editShown = true;
  }

  refresh() {
    this.isLoading = true;
    this._alertTemplatesService.getAlertTemplates()
    .pipe(finalize(() => this.isLoading = false))
    .subscribe((alertTemplates) => this.alertTemplates = alertTemplates, () => this._message.error('Опаньки.. Что-то пошло не так')
    );
  }

  remove(id: string) {
    this._alertTemplatesService.remove(id).subscribe(() => {
      this._message.success('Изменения сохранены');
      this.refresh();
    }, () => this._message.error('Опаньки.. Что-то пошло не так'));
  }

  getWidgetUrl(alertTemplate: IAlertTemplate): string {
    return `${location.protocol}//${location.host}/assets/internal/widgets/index.html?id=general`;
  }

  test() {
    if (!this.testBillType) return;
    this._alertTemplatesService
    .testGeneric(this.testBillType, this.testPrice)
    .subscribe(() => this._message.success('Тестовое сообщение создано'));
  }

}
