<nz-page-header>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>Админка</nz-breadcrumb-item>
    <nz-breadcrumb-item>Цели пожертвований</nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-page-header>

<nz-list [nzLoading]="isLoading">
  <nz-list-header>
    Настроенные цели
    <button nz-button nzSize="small" nzShape="circle" nz-tooltip="Добавить" (click)="add()">
      <i nz-icon nzType="plus"></i>
    </button>
  </nz-list-header>
  <nz-list-item *ngFor="let goal of donationGoals">
    <nz-list-item-meta>
      <nz-list-item-meta-title>
        <nz-tag>{{goal.title}} {{goal.currentAmount / goal.targetAmount * 100}}% {{goal.isActive ? "[Активная]" : ""}}</nz-tag>
      </nz-list-item-meta-title>
    </nz-list-item-meta>

    <ul nz-list-item-actions>
      <nz-list-item-action>
        <button nz-button nzType="link" (click)="edit(goal.id)" nz-tooltip="Редактировать"><i nz-icon
                                                                                                       nzType="edit"></i>
        </button>
      </nz-list-item-action>
      <nz-list-item-action>
        <button nz-button nzType="link" nzDanger nz-popconfirm nzOkType="danger" nzPopconfirmTitle="Удалить цель?"
                (nzOnConfirm)="remove(goal.id)" nz-tooltip="Удалить"><i nz-icon nzType="delete"></i></button>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
</nz-list>

<nz-drawer [(nzVisible)]="editShown" (nzOnClose)="editShown = false" [nzWidth]="'80%'" [nzTitle]="title">
  <ng-template #title>
    <h2 class="nova-font">{{ (donationGoalId !== undefined) ? 'Редактировать' : 'Добавить'}} цель</h2>
  </ng-template>
  <ng-container *nzDrawerContent>
    <app-edit-donation-goal (saved)="refresh()" *ngIf="editShown" [donationGoalId]="donationGoalId"></app-edit-donation-goal>
  </ng-container>
</nz-drawer>
