import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DonationGoalsService} from '../../../services/donation-goals/donation-goals.service';
import {finalize} from 'rxjs/operators';
import {ISaveDonationGoalCommand} from '../../../services/donation-goals/models/save-donation-goal.command';

@Component({
  selector: 'app-edit-donation-goal',
  templateUrl: './edit-donation-goal.component.html',
  styleUrls: ['./edit-donation-goal.component.scss'],
})
export class EditDonationGoalComponent implements OnInit {

  @Input() public donationGoalId?: string;
  @Output() public saved = new EventEmitter();
  public isLoaded = false;
  public isEdit = false;
  public form: FormGroup<{
    title: FormControl<string | null>,
    targetAmount: FormControl<number | null>,
    isActive: FormControl<boolean | null>
  }>;
  public isSaving = false;


  constructor(fb: FormBuilder, private _donationGoalService: DonationGoalsService, private _message: NzMessageService) {
    this.form = fb.group({
      title: [null as (string|null), Validators.required],
      targetAmount: 0,
      isActive: false as boolean,
    });
  }

  ngOnInit(): void {
    this.isEdit = this.donationGoalId !== undefined;
    if (this.isEdit) {
      this._donationGoalService.getDonationGoal(this.donationGoalId!)
        .subscribe((goal) => {

          this.form.patchValue({
            title: goal.title,
            targetAmount: goal.targetAmount,
            isActive: goal.isActive,
          });

          this.isLoaded = true;
        }, () => {
          this._message.error('Опаньки.. Что-то пошло не так');
        });
    } else {
      this.isLoaded = true;
    }
  }

  save() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
      return;
    }
    const command: ISaveDonationGoalCommand = {
      title: this.form.value.title!,
      targetAmount: this.form.value.targetAmount ?? 0,
      isActive: this.form.value.isActive ?? false,
    };

    this.isSaving = true;
    (this.isEdit
        ? this._donationGoalService.updateDonationGoal(this.donationGoalId!, command)
        : this._donationGoalService.createDonationGoal(command)
    ).pipe(
      finalize(() => this.isSaving = false),
    )
      .subscribe((x) => {
        this.saved.emit();
        this.donationGoalId = x;
        this.isEdit = true;
        this._message.success('Изменения сохранены');
      }, () => this._message.error('Опаньки.. Что-то пошло не так'));
  }

}
