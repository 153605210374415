import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { IDonationGoal } from './models/donation-goal.model';
import { ISaveDonationGoalCommand } from './models/save-donation-goal.command';

@Injectable({
  providedIn: 'root'
})
export class DonationGoalsService {

  constructor(private _http: HttpClient) {
  }

  /**
   * Get goals
   */
  public getDonationGoals(): Observable<Array<IDonationGoal>> {
    return this._http
      .get<Array<IDonationGoal>>('/api/donation-goals/');
  }

  /**
   * Get goal by id
   * @param id
   */
  public getDonationGoal(id: string): Observable<IDonationGoal> {
    return this._http
      .get<IDonationGoal>(`/api/donation-goals/${id}`);
  }

  /**
   * Create new goal
   */
  public createDonationGoal(command: ISaveDonationGoalCommand): Observable<string> {
    return this._http
    .put<string>('/api/donation-goals', command);
  }

  /**
   * Update goal
   * @param id
   * @param command
   */
  public updateDonationGoal(id: string, command: ISaveDonationGoalCommand): Observable<string> {
    return this._http
    .post<string>(`/api/donation-goals/${id}`, command);
  }

  /**
   * Remove goal
   * @param id
   */
  public remove(id: string): Observable<void>{
    return this._http
      .delete<void>(`/api/donation-goals/${id}`,);
  }

  /**
   * Get active goal info
   */
  public getActiveGoalInfo(): Observable<IDonationGoal>{
    return this._http
      .get<IDonationGoal>(`api/donation-goals/active`);
  }

}
