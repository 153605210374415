<nz-page-header>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>Админка</nz-breadcrumb-item>
    <nz-breadcrumb-item>Оповещения</nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-page-header>

<nz-list [nzLoading]="isLoading">
  <nz-list-header>
    Настроенные оповещения
    <button nz-button nzSize="small" nzShape="circle" nz-tooltip="Добавить" (click)="add()">
      <i nz-icon nzType="plus"></i>
    </button>
  </nz-list-header>
  <nz-list-item *ngFor="let alertTemplate of alertTemplates">
    <nz-list-item-meta>
      <nz-list-item-meta-title>
        <nz-tag>{{BillTypeData[alertTemplate.billType]?.title}} ({{alertTemplate.minPrice ?? 0}})</nz-tag>
      </nz-list-item-meta-title>
    </nz-list-item-meta>

    <ul nz-list-item-actions>
      <nz-list-item-action>
        <span nz-typography nzCopyable [nzCopyText]="getWidgetUrl(alertTemplate)"></span>
      </nz-list-item-action>
      <nz-list-item-action>
        <button nz-button nzType="link" (click)="edit(alertTemplate.id)" nz-tooltip="Редактировать"><i nz-icon nzType="edit"></i>
        </button>
      </nz-list-item-action>
      <nz-list-item-action>
        <button nz-button nzType="link" nzDanger nz-popconfirm nzOkType="danger" nzPopconfirmTitle="Удалить оповещение?"
                (nzOnConfirm)="remove(alertTemplate.id)" nz-tooltip="Удалить"><i nz-icon nzType="delete"></i></button>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
</nz-list>

<nz-page-header-footer>
  <nz-divider></nz-divider>
  <nz-space>
    <nz-select *nzSpaceItem [nzDropdownMatchSelectWidth]="false" [(ngModel)]="testBillType" nzMode="default">
      <nz-option nzCustomContent *ngFor="let bill of BillTypes" [nzLabel]="BillTypeData[bill]?.title ?? 'Unknown type'"
                 [nzValue]="bill">
        <img alt="Type image" [src]="'/assets/img/icons/donate/'+BillTypeData[bill]?.image" style="height: 2em; width: auto;"/>
        {{BillTypeData[bill]?.title}}
      </nz-option>
    </nz-select>
    <nz-input-number *nzSpaceItem [(ngModel)]="testPrice" [nzMin]="0"></nz-input-number>
    <button *nzSpaceItem nz-button nzType="default" (click)="test()">Проверить</button>
  </nz-space>
</nz-page-header-footer>


<nz-drawer [(nzVisible)]="editShown" (nzOnClose)="editShown = false" [nzWidth]="'80%'" [nzTitle]="title">
  <ng-template #title>
    <h2 class="nova-font">{{ (alertTemplateId !== undefined) ? 'Редактировать' : 'Добавить'}} оповещение</h2>
  </ng-template>
  <ng-container *nzDrawerContent>
    <app-edit-alert (added)="refresh()" *ngIf="editShown" [alertTemplateId]="alertTemplateId"></app-edit-alert>
  </ng-container>
</nz-drawer>
