import { Component } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DonationGoalsService } from '../../services/donation-goals/donation-goals.service';
import { IDonationGoal } from '../../services/donation-goals/models/donation-goal.model';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-donation-goals',
  templateUrl: './donation-goals.component.html',
  styleUrls: ['./donation-goals.component.scss'],
})
export class DonationGoalsComponent {

  public isLoading: boolean = false;
  public donationGoals: Array<IDonationGoal> = [];
  public editShown = false;
  public donationGoalId?: string;

  constructor(private _donationGoalsService: DonationGoalsService, private _message: NzMessageService) {
    this.refresh();
  }

  public add(): void {
    this.donationGoalId = undefined;
    this.editShown = true;
  }

  refresh() {
    this.isLoading = true;
    this._donationGoalsService.getDonationGoals()
    .pipe(finalize(() => this.isLoading = false))
    .subscribe((goals) => this.donationGoals = goals, () => this._message.error('Опаньки.. Что-то пошло не так'),
    );
  }

  public edit(id: string): void {
    this.donationGoalId = id;
    this.editShown = true;
  }

  public remove(id: string): void {
    this._donationGoalsService.remove(id).subscribe(() => {
      this._message.success('Изменения сохранены');
      this.refresh();
    }, () => this._message.error('Опаньки.. Что-то пошло не так'));

  }

}
