<ng-container *ngIf="isLoaded else skeleton">
  <div class="clearfix">
    <div nz-row>
      <form nz-form [formGroup]="form" (ngSubmit)="save()" nz-col [nzSm]="24" [nzMd]="12" [nzLg]="4"
            nzLayout="vertical">
        <!-- PAYMENT OPTIONS -->
        <nz-form-item>
          <nz-form-label nzFor="billTypes">Триггеры</nz-form-label>
          <nz-form-item>
            <nz-select [nzDropdownMatchSelectWidth]="false" nzMode="default" formControlName="billType">
              <nz-option nzCustomContent *ngFor="let bill of BillTypes" [nzLabel]="BillTypeData[bill]?.title ?? ''"
                         [nzValue]="bill">
                <img [src]="'/assets/img/icons/donate/'+BillTypeData[bill]?.image" style="height: 2em; width: auto;"/>
                {{BillTypeData[bill]?.title}}
              </nz-option>
            </nz-select>
          </nz-form-item>
        </nz-form-item>
        <!-- ANIMATION FILE -->
        <nz-form-item>
          <nz-form-label nzFor="animationFile">Анимация</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nz-upload nzAction="/api/files" [nzAccept]="'image/gif'" [nzMultiple]="false"
                       [(nzFileList)]="animationFileList"
                       [nzShowUploadList]="{showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: true}"
                       [nzShowButton]="animationFileList.length === 0" (nzChange)="handleAnimationFileChange($event)">
              <button nz-button type="button">
                <i nz-icon nzType="upload"></i>
                Выберите файл
              </button>
            </nz-upload>
          </nz-form-control>
        </nz-form-item>
        <!-- SOUND FILE -->
        <nz-form-item>
          <nz-form-label nzFor="soundFileId">Звук</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nz-upload nzAction="/api/files" [nzAccept]="'.ogg,.mp3,.wav'" [nzMultiple]="false"
                       [(nzFileList)]="soundFileList"
                       [nzShowUploadList]="{showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: true}"
                       [nzShowButton]="soundFileList.length === 0" (nzChange)="handleSoundFileChange($event)">
              <button nz-button type="button">
                <i nz-icon nzType="upload"></i>
                Выберите файл
              </button>
            </nz-upload>
          </nz-form-control>
        </nz-form-item>

        <!-- VOLUME -->
        <nz-form-item>
          <nz-form-label nzFor="volume">Громкость</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nz-slider [nzMin]="0" [nzMax]="1" [nzStep]="0.01"  formControlName="volume"></nz-slider>
          </nz-form-control>
        </nz-form-item>

        <!-- MIN PRICE -->
        <nz-form-item>
          <nz-form-label nzFor="minPrice">Минимальная стоимость</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <nz-input-number [nzMin]="0" formControlName="minPrice"></nz-input-number>
          </nz-form-control>
        </nz-form-item>

        <!-- SUBMIT BUTTON -->
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [nzLoading]="isSaving">Сохранить</button>
          </nz-form-control>
        </nz-form-item>
      </form>
      <ng-container *ngIf="isEdit">
        <nz-divider></nz-divider>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="default" (click)="test()">Проверить</button>
        </nz-space>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <nz-skeleton [nzActive]="true" [nzParagraph]="{rows: 6}"></nz-skeleton>
</ng-template>
