import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { RouterModule } from '@angular/router';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { SharedModule } from '../shared/shared.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EditAlertComponent } from './alerts/edit/edit-alert.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { DonationGoalsComponent } from './donation-goals/donation-goals.component';
import { EditDonationGoalComponent } from './donation-goals/edit-donation-goal/edit-donation-goal.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { ShortLinksComponent } from './short-links/short-links.component';


@NgModule({
  declarations: [
    AlertsComponent,
    EditAlertComponent,
    DonationGoalsComponent,
    EditDonationGoalComponent,
    ShortLinksComponent,
  ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'alerts',
                component: AlertsComponent,
            },
            {
                path: 'donation-goals',
                component: DonationGoalsComponent,
            },
        ]),
        NzPageHeaderModule,
        NzBreadCrumbModule,
        SharedModule,
        NzDrawerModule,
        NzListModule,
        NzTagModule,
        NzTypographyModule,
        NzToolTipModule,
        NzUploadModule,
        NzSelectModule,
        NzSkeletonModule,
        NzPopconfirmModule,
        NzSpaceModule,
        NzDividerModule,
        NzSliderModule,
        NzInputNumberModule,
        NzCheckboxModule,
    ],
})
export class AdminModule {
}
